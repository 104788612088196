@import "_fonts";

$countries: at be ch de es fr gb ie it lt nl gr pt;

body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

html {
  min-height: 100%;
  background-image: url("/img/global-background/background.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  font-family: "TreatwellSansWeb", sans-serif;
}

#content {
  max-width: 400px;
  margin: 32px 0 0 48px;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}

a {
  color: #ffffff;
  text-decoration: none;
}

h1.logo {
  margin: 0;
  text-indent: -9999px;
  overflow: hidden;
  width: 320px;
  height: 80px;
  background: url("/img/logo-white.svg") no-repeat;
}

p.tagline {
  margin: 0;
  font-size: 24px;
  line-height: 32px;
  margin-top: 8px;
  text-shadow: 0 1px 2px rgba(#000000, 0.3);
}

ul.countries {
  margin: 32px 0 0 0;
  padding: 0;
  list-style: none;

  li {
    background: 0 center no-repeat;

    a {
      display: block;
      height: 40px;
      padding: 8px 0 8px 40px;
    }
  }

  @each $country in $countries {
    li.#{$country} {
      background-image: url("/img/flags/flag-#{$country}.svg");
    }
  }
}

.redirect-jobs a.jobs {
  color: #001e62;
}

a.jobs {
  display: block;
  margin-top: 24px;
  margin-bottom: 32px;
  font-weight: bold;
}

@media (max-width: 560px) {
  html {
    background-image: url("/img/global-background/background-mobile.jpg");
    background-position: top;
    background-size: cover;
  }

  body {
    margin-bottom: 520px;
    margin-bottom: 130vw;
  }

  #content {
    margin: 16px 0 0 16px;
  }

  h1.logo {
    width: 160px;
    height: 40px;
    background: url("/img/logo-white-small.svg") no-repeat;
  }

  p.tagline {
    font-size: 16px;
    line-height: 24px;
  }
}
