@font-face {
  font-family: TreatwellSansWeb;
  src: url("/fonts/TreatwellSansWeb-Regular.eot");
  src: url("/fonts/TreatwellSansWeb-Regular.eot?#iefix") format("embedded-opentype"),
       url("/fonts/TreatwellSansWeb-Regular.woff") format("woff"),
       url("/fonts/TreatwellSansWeb-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: TreatwellSansWeb;
  src: url("/fonts/TreatwellSansWeb-Bold.eot");
  src: url("/fonts/TreatwellSansWeb-Bold.eot?#iefix") format("embedded-opentype"),
       url("/fonts/TreatwellSansWeb-Bold.woff") format("woff"),
       url("/fonts/TreatwellSansWeb-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}